/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { useBlockchain, useNewCollection } from "@hapchain-nft/hooks";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import { ThirdwebNftMedia } from "@thirdweb-dev/react";
import { truncateAddress } from "@hapchain-nft/utils";
const NewCollection = () => {
  const router = useRouter();
  const { blockExplorer } = useBlockchain();
  const { data, loading } = useNewCollection();
  return (
    <Grid container spacing={10} flexDirection={"column"}>
      <Grid item>New Collection</Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {!loading &&
            data.map((row, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  className="min-w-[90%] md:min-w-0"
                  md={4}
                  lg={3}
                  xl={2}
                >
                  <Card
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                      },
                    }}
                    onClick={() => {
                      router.push(`/collection/${row.contractAddress}`);
                    }}
                  >
                    <CardMedia sx={{ height: "230px" }}>
                      <ThirdwebNftMedia
                        metadata={row.metadata as any}
                        height="100%"
                        width="100%"
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    </CardMedia>
                    <CardContent>
                      <Stack direction={"column"} gap={1}>
                        <Typography
                          variant="h5"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {row.name}
                        </Typography>
                        <Stack
                          direction={"row"}
                          gap={2}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="body2" className="text-txt-gray">
                            Floor Price
                          </Typography>
                          <Typography
                            variant="body1"
                            className="text-txt-gray"
                            style={{
                              textAlign: "right",
                            }}
                          >
                            Total volume
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          gap={2}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="body2" className="text-txt-gray">
                            Owned by
                          </Typography>

                          <Link
                            target="_blank"
                            href={`${blockExplorer}/address/${row.creator?.walletAddress}`}
                          >
                            <Stack direction="row" spacing={1}>
                              <Typography
                                variant="body2"
                                className="text-a-green"
                              >
                                {truncateAddress(
                                  row.creator?.walletAddress as string
                                )}
                              </Typography>
                              <LaunchIcon fontSize="small" />
                            </Stack>
                          </Link>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          {!loading && data.length === 0 && (
            <Grid item xs={12}>
              <Typography variant="h5" align={"center"}>
                No data
              </Typography>
            </Grid>
          )}
          {loading &&
            Array.from(Array(6).keys()).map((item, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  className="min-w-[90%] md:min-w-0"
                  md={4}
                  lg={3}
                  xl={2}
                >
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={230}
                    animation="wave"
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default NewCollection;
