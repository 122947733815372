/* eslint-disable react/react-in-jsx-scope */
import CategoryNavigateTop from "@/components/CategoryNavigateTop/CategoryNavigateTop";
import CreatorsBanner from "@/components/CreatorsBanner";
import DefaultContainer from "@/components/DefaultCotainer";
import NewCollection from "@/components/NewCollection";
import RecentCategory from "@/components/RecentCategory";
import TopBid from "@/components/TopBid";
import MainLayout from "@/components/layouts/main";
import { useMasterData } from "@hapchain-nft/hooks";
import { Grid } from "@mui/material";
// import { Button } from "@mui/material";
import { NextPage } from "next";

const Home: NextPage = () => {
  const { categories } = useMasterData();
  return (
    <MainLayout disablePadding>
      <DefaultContainer title="">
        <Grid
          container
          spacing={4}
          sx={{
            marginTop: "-50px",
            padding: "20px",
          }}
          flexDirection={"row"}
        >
          <Grid item xs={12}>
            <CreatorsBanner />
          </Grid>
          <Grid item xs={12}>
            <CategoryNavigateTop />
          </Grid>
          <Grid item xs={12}>
            <TopBid />
          </Grid>
          <Grid item xs={12}>
            <NewCollection />
          </Grid>
          <Grid item xs={12}>
            {categories.map((category, index) => (
              <RecentCategory
                key={index}
                categoryId={category.id}
                name={category.name}
              />
            ))}
          </Grid>
        </Grid>
      </DefaultContainer>
    </MainLayout>
  );
};
export default Home;
