/* eslint-disable react/react-in-jsx-scope */
import { useMasterData } from "@hapchain-nft/hooks";
import { Button, Stack } from "@mui/material";
import { useRouter } from "next/router";
const CategoryNavigateTop = () => {
  const { categories } = useMasterData();
  const router = useRouter();
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined">All</Button>
      {categories.map((category, index) => (
        <Button
          key={index}
          variant="text"
          onClick={() => {
            router.push(`/categories/${category.id}`);
          }}
        >
          {category.name}
        </Button>
      ))}
    </Stack>
  );
};
export default CategoryNavigateTop;
