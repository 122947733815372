/* eslint-disable @typescript-eslint/no-explicit-any */
import { toEtherDisplay, useTopBid } from "@hapchain-nft/hooks";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import Image from "next/image";
import { ThirdwebNftMedia } from "@thirdweb-dev/react";
/* eslint-disable react/react-in-jsx-scope */
const TopBid = () => {
  const router = useRouter();
  const { data, loading } = useTopBid();
  return (
    <Grid container spacing={10} flexDirection={"column"}>
      <Grid item>Top BID</Grid>
      <Grid item xs={12}>
        <Grid container spacing={10}>
          {!loading &&
            data.map((row, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  className="min-w-[90%] md:min-w-0"
                  md={4}
                  lg={3}
                  xl={2}
                >
                  <Card
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                      },
                    }}
                    onClick={() => {
                      router.push(`/collection/${row.contractAddress}`);
                    }}
                  >
                    <CardMedia sx={{ height: "230px" }}>
                      <ThirdwebNftMedia
                        metadata={row.metadata as any}
                        height="100%"
                        width="100%"
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    </CardMedia>
                    <CardContent>
                      <Stack direction={"column"} gap={1}>
                        <Typography
                          variant="h5"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {row.name}
                        </Typography>
                        <Stack
                          direction={"row"}
                          gap={2}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="body2" className="text-txt-gray">
                            Floor Price
                          </Typography>
                          <Typography
                            variant="body1"
                            className="text-txt-gray"
                            style={{
                              textAlign: "right",
                            }}
                          >
                            Total volume
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          gap={2}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <Image
                              src="https://ipfs.io/ipfs/QmQ4V9JC25yUrYk2kFJwmKguSsZBQvtGcg6q9zkDV8mkJW?filename=hap.png"
                              width={20}
                              height={20}
                              alt="hap"
                            />
                            <Typography
                              variant="body2"
                              className="text-txt-gray"
                            >
                              {toEtherDisplay(row.stock_data?.min)} HAP
                            </Typography>
                          </Stack>
                          <Typography variant="body1" className="text-txt-gray">
                            {toEtherDisplay(row.stock_data?.sum)} HAP
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          {!loading && data.length === 0 && (
            <Grid item xs={12}>
              <Typography variant="h5" align={"center"}>
                No data
              </Typography>
            </Grid>
          )}
          {loading &&
            Array.from(Array(6).keys()).map((item, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  className="min-w-[90%] md:min-w-0"
                  md={4}
                  lg={3}
                  xl={2}
                >
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={230}
                    animation="wave"
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TopBid;
