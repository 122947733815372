/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/react-in-jsx-scope */

import { useLastCreatorMint } from "@hapchain-nft/hooks";
import { Grid } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const CreatorsBanner = () => {
  const { loading, lastCreatorMint } = useLastCreatorMint();
  return (
    <Grid container spacing={10} flexDirection={"column"}>
      <Grid item>Cover</Grid>
      <Grid item xs={12}>
        <Carousel
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          autoPlay={true}
          autoPlaySpeed={5000}
          containerClass="carousel-container"
          itemClass="carousel-item-padding-40-px"
        >
          {!loading &&
            lastCreatorMint.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: 350,
                    height: 192,
                  }}
                >
                  <img
                    src={item.avatar}
                    style={{
                      width: 350,
                      height: 192,
                      objectFit: "cover",
                      borderRadius: 10,
                      backgroundColor: "#fff",
                    }}
                    alt={item.displayName}
                  />
                </div>
              );
            })}
        </Carousel>
      </Grid>
    </Grid>
  );
};
export default CreatorsBanner;
